.app{
    display:flexbox;
}
.scan{
    display: grid;
    justify-content: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.scan > h2{
    text-align: center;
}

.scan__canvasContainer{
    display: grid;
    box-shadow: 5px 10px 18px #888888;
    margin: 5%;
    place-items: center;
    place-content: center;
    overflow: hidden;
    grid-template: "container";
    align-items: flex-start;
}


.scan__canvasContainer > *{
    grid-area: container;
    max-width: 100%;
}
.skip__BtnCont{
    display: flex;
    justify-content:flex-end;
    align-items: center;
    
}
.skip__BtnCont >button{
    text-align: center;
    width: 100px;
    height: 50px;
    border-radius: 25px;
    border: 0;
    background-color: deepskyblue;
    font-weight: bolder;
    font-size: medium;
}
#skip_btn:hover{
    background-color: deepskyblue
}
#skip_btn:active{
    background-color: deepskyblue;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}
.scan__canvasContainer >canvas{
    height: auto;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}

.scan__vitalBox{
    display: flex;
    justify-content:space-evenly;
    width: 100%;
    padding: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
}

.scan__Box{
    display: block;
    text-align: center;
    background-color:#f5f7f7;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* width: 15vh; */
    /* height: 10vh; */
    min-width: 15vw;
    margin-top: -5% !important;
    margin-left: 1%;
    margin-right: 1%;
}

.scan__Box > label{
    font-weight: bolder;
    color:deepskyblue;
    /* width: 90%; */
    /* height: 40%; */
    padding: 5px;
    margin-top: 5px;
}

.scan__Box > input{
height: 30px !important;
}

#diag_label{
    color: mediumaquamarine;
}

.scan__Box >input{
    width: 90%;
    height: 60%;
    outline: 0;
    border:0;
    background-color:#f5f7f7;
    text-align: center;
    font-weight: bolder;
    font-size: xx-large;
    color:grey;
}
.scan__BtnCont{
    display: flex;
    justify-content: center;
    align-items: center;
}
.scan__BtnCont >button{
    text-align: center;
    width: 100px;
    height: 50px;
    border-radius: 25px;
    border: 0;
    background-color: deepskyblue;
    font-weight: bolder;
    font-size: medium;
}
#start_stop_btn:hover{
    background-color: deepskyblue
}
#start_stop_btn:active{
    background-color: deepskyblue;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}
#diagnosis{
    text-align: center;
    font-weight: bolder;
    color:grey;
    font-size: large;
}
.progress__container{
    width: 80%;
    height:fit-content;
    margin: 2%;
}

#progress__value{
    width: 1%;
    height: 30px;
    background-color:rgba(0, 255, 0, 0.5);
    text-align: center;
    line-height: 30px;
    color: white;
    border-radius: 20px;
}

.progress__header{
    text-align: center;
    z-index: 1;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    width: fit-content;
    height: fit-content;
}

.tabular__container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: deepskyblue;
    border-radius: 5px;
    width: 90%;
    margin: 5%;
    /* width: %;
    height: 50%; */
    box-shadow: 5px 10px 18px #888888;
}

.tabular__container > button{
    /* text-align: center;
    border: 0;*/
    font-weight: bolder;
    font-size: medium;
    border: none;
    border-right: 1px dotted #f5f7f7;
    width: 100%;
    height: 35px;
    background-color: transparent;
    color: white;
    outline: 0;
}

#tab_basic:hover{
    background-color: deepskyblue
}

#tab_bp:hover{
    background-color: deepskyblue
}
.logo {
    /* margin-top: 5%; */
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 18%;
    text-align: center;
  }